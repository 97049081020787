import "~/utils/patch-fetch-with-request-id";

import React, { startTransition, StrictMode, useState } from "react";
import { hydrateRoot } from "react-dom/client";
import { CacheProvider } from "@emotion/react";
import { RemixBrowser } from "@remix-run/react";
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
} from "@sentry/integrations";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/remix";
import { ClientStyleContext } from "./context";
import { createEmotionCache, defaultCache } from "./create-emotion-cache";
import { env } from "~/utils/frontend-env";
import { enableMixpanel, enableSentry, SENTRY_DSN } from "~/constants";

interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps): JSX.Element {
  const [cache, setCache] = useState(defaultCache);

  function reset(): void {
    setCache(createEmotionCache());
  }

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

function hydrate(): void {
  startTransition(() => {
    hydrateRoot(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Part of hack for hydrate error https://github.com/kiliman/remix-hydration-fix
      document.getElementById("root")!,
      <StrictMode>
        <ClientCacheProvider>
          <RemixBrowser />
        </ClientCacheProvider>
      </StrictMode>
    );
  });
}

if (enableSentry) {
  Sentry.init({
    release: env.VERCEL_GIT_COMMIT_SHA,
    dsn: SENTRY_DSN,
    integrations: [
      // new Sentry.BrowserTracing({
      //   routingInstrumentation: Sentry.remixRouterInstrumentation(
      //     useEffect,
      //     useLocation,
      //     useMatches
      //   ),
      // }),
      new ExtraErrorDataIntegration(),
      new HttpClientIntegration({
        failedRequestStatusCodes: [[400, 599]],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        networkRequestHeaders: ["X-Double-Id"],
        networkResponseHeaders: [
          "Content-Encoding",
          "Content-Type",
          "Date",
          "Fly-Request-Id",
          "Server",
          "Vary",
          "Via",
        ],
        networkDetailAllowUrls: [window.location.origin],
      }),
    ],
    // tracesSampleRate: 0.2,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: env.REACT_APP_ENV,
    sendDefaultPii: true,
  });
}

if (enableMixpanel) {
  mixpanel.init("64cc8ce20e624eeb987e3e0615c118cb", {
    api_host: "https://proxy-fyvyjcfzwq-uw.a.run.app/",
  });
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Guard for Safari
if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
