import { customAlphabet } from "nanoid";

export enum Tag {
  Req = "req", // Request in distributed tracing
  Span = "span", // Span in distributed tracing
  Inngest = "inng", // Inngest Run ID
}

// Alphabet without similar looking characters
const nolookalikes = "23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz";
const length = 21; // Length of the id, you can change this to your desired length
const nanoid = customAlphabet(nolookalikes, length);

export function generateTaggedId(tag: Tag): string {
  const id = `${tag}_${nanoid()}`;

  return id;
}
