import { Tag, generateTaggedId } from "~/utils/generate-tagged-id";

const originalFetch = window.fetch;

window.fetch = (input: RequestInfo | URL, init: RequestInit = {}): Promise<Response> => {
  const requestId = generateTaggedId(Tag.Req);

  let requestOrigin: string;

  if (typeof input === "string") {
    try {
      requestOrigin = new URL(input).origin;
    } catch (e) {
      if (input.startsWith("/")) {
        // If the URL is a relative URL, it's a same-origin request
        requestOrigin = window.location.origin;
      } else {
        // If the URL is invalid, just proceed with the original fetch
        return originalFetch(input, init);
      }
    }
  } else if (input instanceof Request) {
    try {
      requestOrigin = new URL(input.url).origin;
    } catch (e) {
      // If the URL in the Request object is invalid, just proceed with the original fetch
      return originalFetch(input, init);
    }
  } else if (input instanceof URL) {
    requestOrigin = input.origin;
  } else {
    // If the input type is unexpected, just proceed with the original fetch
    return originalFetch(input, init);
  }

  const isSameOrigin = requestOrigin === window.location.origin;
  if (isSameOrigin) {
    return originalFetch(input, {
      ...init,
      headers: {
        ...(init.headers instanceof Headers
          ? Object.fromEntries(init.headers.entries())
          : init.headers),
        "x-double-id": requestId,
      },
    });
  }

  return originalFetch(input, init);
};
